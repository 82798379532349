<script>
import authService from '@/services/auth/auth'

export default {
  props: {
    groupLogonMasterProp: Object
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    getTargetGroupInfo() {
      if (this.groupLogonMasterProp) {
        const { groupCode, groupName } = this.groupLogonMasterProp
        return [
          { key: 'groupCode', value: groupCode || '-', label: 'รหัส' },
          { key: 'groupName', value: groupName || '-', label: 'ชื่อ' }
        ]
      }
      return []
    }
  },
  methods: {
    buttonClicked(type) {
      switch (type) {
        case 'CONFIRM':
          if (this.groupLogonMasterProp) {
            this.deleteGroupLogonMaster(this.groupLogonMasterProp.groupCode)
          }
          break
        case 'CANCEL':
          this.$emit('closeModal', {
            type: 'CLOSE'
          })
          break
        default:
          break
      }
    },
    async deleteGroupLogonMaster(groupCode) {
      this.isLoading = true
      try {
        await authService.deleteGroupLogonMaster(groupCode)
        this.$emit('closeModal', {
          type: 'CLOSE_WITH_SUCCESS',
          message: 'ลบผู้ใช้งานสำเร็จ'
        })
      } catch (err) {
        this.$emit('closeModal', {
          type: 'DISPLAY_ERROR',
          message: err.response.data.thMessage
        })
      }
      this.isLoading = false
    }
  }
}
</script>

<template>
  <div v-if="groupLogonMasterProp">
    <b-row align-v="start">
      <!-- trash icon -->
      <b-col class="col-3">
        <b-icon
          icon="trash-fill"
          variant="danger"
          class="mt-2 icon-style"
        ></b-icon>
      </b-col>

      <!-- content -->
      <b-col class="col-9">
        <h5>รายละเอียดของกลุ่มผู้ใช้งานที่จะลบ</h5>
        <div
          class="d-flex mt-3"
          v-for="info in getTargetGroupInfo"
          :key="info.key"
        >
          <span> {{ info.label }}: </span>
          <span class="ml-2 font-weight-bold">
            {{ info.value }}
          </span>
        </div>
      </b-col>
    </b-row>

    <!-- hover line -->
    <hr />

    <!-- butttons -->
    <b-row align-h="end">
      <b-col class="col-3">
        <b-button
          class="w-100"
          variant="danger"
          @click="buttonClicked('CONFIRM')"
          :disabled="isLoading"
        >
          <b-spinner v-if="isLoading" variant="light" small></b-spinner>
          <span v-else>ลบ</span>
        </b-button>
      </b-col>
      <b-col class="col-3">
        <b-button
          class="w-100"
          variant="outline-danger"
          @click="buttonClicked('CANCEL')"
          :disabled="isLoading"
        >
          ยกเลิก
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>
.icon-style {
  width: 100%;
  height: 100%;
}
</style>

<script>
import { mapState } from 'vuex'

import { toastMixins, datetimeMixins } from '@/mixins'

import authService from '@/services/auth/auth'

import MenuBar from './components/MenuBar.vue'

export default {
  mixins: [toastMixins, datetimeMixins],
  components: {
    MenuBar
  },
  data() {
    return {
      sortBySelected: 'groupCode',
      sortByOptions: [
        { value: 'groupCode', text: 'รหัสกลุ่มผู้ใช้งาน' },
        { value: 'groupName', text: 'ชื่อกลุ่มผู้ใช้งาน' },
        { value: 'createdAt', text: 'สร้างเมื่อ' },
        { value: 'updatedAt', text: 'แก้ไขล่าสุดเมื่่อ' }
      ],
      sortTypeSelected: 'asc',
      sortTypeOptions: [
        { value: 'asc', text: 'แรกสุด - ท้ายสุด' },
        { value: 'desc', text: 'ท้ายสุด - แรกสุด' }
      ],
      searchBySelected: 'groupCode',
      searchByOptions: [{ value: 'groupCode', text: 'รหัสกลุ่มผู้ใช้งาน' }],
      searchKeyword: '',
      searchKeywordSetTimer: null,
      tableFields: [
        {
          field: 'groupCode',
          title: 'รหัสกลุ่มผู้ใช้งาน',
          align: 'center'
        },
        {
          field: 'groupName',
          title: 'ชื่อกลุ่มผู้ใช้งาน',
          align: 'center'
        },
        {
          field: 'createdAtFormat',
          title: 'สร้างเมื่อ',
          align: 'center'
        },
        {
          field: 'updatedAtFormat',
          title: 'ปรับปรุงเมื่อ',
          align: 'center'
        }
      ],
      groupLogonMasterSelected: null,
      groupLogonMasters: [],
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      defaultKeywordTimerMs: (state) => state.constants.defaultKeywordTimerMs
    })
  },
  watch: {
    sortBySelected() {
      this.fetchGroupLogonMasters()
    },
    sortTypeSelected() {
      this.fetchGroupLogonMasters()
    },
    searchBySelected() {
      this.fetchGroupLogonMasters()
    },
    searchKeyword() {
      if (this.searchKeywordSetTimer) clearTimeout(this.searchKeywordSetTimer)

      this.searchKeywordSetTimer = setTimeout(() => {
        this.fetchGroupLogonMasters()
      }, this.defaultKeywordTimerMs)
    }
  },
  async created() {
    await this.fetchGroupLogonMasters()
  },
  methods: {
    async fetchGroupLogonMasters() {
      this.groupLogonMasterSelected = null
      try {
        const qsArray = []
        if (this.sortBySelected) qsArray.push(`sortBy=${this.sortBySelected}`)
        if (this.sortTypeSelected)
          qsArray.push(`sortType=${this.sortTypeSelected}`)
        // Not support searchBy yet
        // if (this.searchBySelected)
        //   qsArray.push(`searchBy=${this.searchBySelected}`)
        if (this.searchKeyword && this.searchKeyword.trim().length > 0)
          qsArray.push(`keyword=${this.searchKeyword.trim()}`)
        const qs = qsArray.join('&')

        const res = await authService.getGroupLogonMasters({ qs })
        this.groupLogonMasters = this.formatResults(res.data.data)
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      }
    },
    formatResults(groups) {
      return groups.map((group) => {
        const { createdAt, updatedAt, ...restGroup } = group

        return {
          ...restGroup,
          createdAtFormat: this.mxConvertToDatetimeBuddhistFormat(createdAt),
          updatedAtFormat: this.mxConvertToDatetimeBuddhistFormat(updatedAt)
        }
      })
    },
    getSearchKeywordPlaceholder(searchBySelected) {
      return (
        this.searchByOptions.find((option) => option.value === searchBySelected)
          .text || ''
      )
    },
    rowClickedHandler(selectedGroupLogonMaster) {
      this.groupLogonMasterSelected = selectedGroupLogonMaster
    },
    rowDoubleClickedHandler(groupLogonMaster) {
      this.$router.push(
        `/admin/group-logon-masters/detail/${groupLogonMaster.groupCode}`
      )
    },
    getRowCss(row) {
      if (this.groupLogonMasterSelected) {
        if (this.groupLogonMasterSelected.id === row.id) {
          return { backgroundColor: 'lightblue' }
        }
      }
      return null
    },
    useStyles(key) {
      switch (key) {
        case 'datagrid-style':
          return { height: '500px' }
        default:
          return {}
      }
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- แถบ menu ด้านบน -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="กลุ่มผู้ใช้งานทั้งหมด"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar
          :groupLogonMasterProp="groupLogonMasterSelected"
          @updateTable="fetchGroupLogonMasters"
        ></menu-bar>
      </b-col>
    </b-row>

    <!-- ส่วนของ selection & search -->
    <b-row class="my-3 d-flex justify-content-center">
      <b-col cols="12">
        <b-row>
          <b-col cols="12" md="2">
            <label class="text-white text-sm" for="sort-by-selection">
              เรียงตาม
            </label>
            <b-form-select
              id="sort-by-selection"
              v-model="sortBySelected"
              :options="sortByOptions"
              style="font-size: 0.9rem"
            ></b-form-select>
          </b-col>
          <b-col cols="12" md="3">
            <label class="text-white text-sm" for="sort-type-selection">
              เรียงแบบ
            </label>
            <b-form-select
              id="sort-type-selection"
              v-model="sortTypeSelected"
              :options="sortTypeOptions"
              style="font-size: 0.9rem"
            ></b-form-select>
          </b-col>
          <b-col cols="12" md="2"></b-col>
          <b-col cols="12" md="2">
            <!-- Not support searchBy yet -->
            <!-- <label class="text-white text-sm" for="sort-type-selection">
              ค้นหาโดย
            </label>
            <b-form-select
              id="sort-type-selection"
              v-model="searchBySelected"
              :options="searchByOptions"
              style="font-size: 0.9rem;"
            ></b-form-select> -->
          </b-col>
          <b-col cols="12" md="3">
            <label class="text-white text-sm" for="search-input"> ค้นหา </label>
            <b-form-input
              id="search-input"
              v-model="searchKeyword"
              :placeholder="getSearchKeywordPlaceholder(this.searchBySelected)"
              style="font-size: 0.9rem"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- ส่วนของ table -->
    <b-row class="d-flex flex-column align-items-center">
      <b-col cols="12" class="text-center">
        <div class="bg-light py-2">
          <h3>กลุ่มผู้ใช้งานในระบบ</h3>
        </div>
      </b-col>
      <b-col cols="12" class="text-center">
        <DataGrid
          :data="groupLogonMasters"
          :rowCss="getRowCss"
          :style="useStyles('datagrid-style')"
          @rowClick="rowClickedHandler"
          @rowDblClick="rowDoubleClickedHandler"
        >
          <GridColumn
            v-for="field in tableFields"
            :key="field.field"
            :field="field.field"
            :title="field.title"
            :align="field.align"
            :width="field.width"
          ></GridColumn>
        </DataGrid>
      </b-col>
    </b-row>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style></style>
